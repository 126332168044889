<template>
  <div class="callRingLogContainerFlexColumn">
    <div class="callRingLogContainerFlexColumn__top">
      <div class="title">电话响铃日志</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-form-item label="响铃时间(起)">
        <el-date-picker v-model="queryParams.startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-form-item label="响铃时间(止)">
        <el-date-picker v-model="queryParams.endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-input field="username" label="客服名称"></dc-input>
    </dc-search-panel>

    <div class="buttons">
      <el-button type="primary" @click="getNowDay">当天</el-button>
      <el-button type="primary" @click="getMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </div>

    <div class="callRingLogContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams">
        <el-table-column prop="username" width="120" label="客服名称" align="center"></el-table-column>
        <el-table-column prop="agent" width="80" label="所属分机" align="center"></el-table-column>
        <el-table-column prop="callingno" width="100" label="主叫号码" align="center"></el-table-column>
        <el-table-column prop="calledno" width="120" label="被叫号码" align="center"></el-table-column>
        <el-table-column prop="orgcalledno" width="100" label="原始被叫号码" align="center"></el-table-column>
        <el-table-column prop="serialid" width="300" label="呼叫座席标识" align="center"></el-table-column>

        <el-table-column prop="serviceDirect" label="呼叫类型/方向" align="center" width="100">
          <template #default="scope">
            {{ scope.row.servicedirect | getServiceDirectText }}
          </template>
        </el-table-column>
        <el-table-column prop="callid" label="呼叫标示" align="center" width="240"> </el-table-column>
        <el-table-column prop="taskid" label="外呼任务号" align="center" width="120"> </el-table-column>
        <el-table-column prop="userdn" label="对方号码" align="center" width="120"> </el-table-column>
        <el-table-column prop="agentdn" label="座席号码" align="center" width="120"> </el-table-column>
        <el-table-column prop="areacode" label="区号" align="center" width="120"> </el-table-column>
        <el-table-column prop="networkinfo" label="网关" align="center" width="120"> </el-table-column>
        <el-table-column prop="queuetime" label="排队时间" align="center" width="120"> </el-table-column>
        <el-table-column prop="opagentid" label="对方座席工号" align="center" width="120"> </el-table-column>
        <el-table-column prop="ringtimer" label="响铃时间" align="center" width="120">
          <template #default="scope">
            {{ scope.row.ringtimer | dateFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="projectid" label="项目编号" align="center" width="120"> </el-table-column>
        <el-table-column prop="servername" label="录音文件服务器" align="center" width="120"> </el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "CallRingLog",
  filters: {
    dateFilter(timestamp) {
      return moment(timestamp, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
    },
    getServiceDirectText(type) {
      if (type == 1) return "精确式外呼";
      else if (type == 2) return "预览式外呼(人工发起)";
      else if (type == 3) return "人工外呼";
      else if (type == 4) return "自动外呼";
      else if (type == 5) return "内部呼叫";
      else if (type == 6) return "咨询";
      else if (type == 7) return "单步转移";
      else if (type == 8) return "桥接";
      else if (type == 9) return "监听";
      else if (type == 10) return "拦截";
      else if (type == 11) return "强插";
      else if (type == 12) return "渐进式外呼";
      else if (type == 13) return "预测式外呼";
      else if (type == 14) return "精确预览外呼（自动发起）";
      else if (type == 19) return "辅助";
      else if (type == 20) return "强拆";
      else if (type == 21) return "强制置闲;";
      else if (type == 22) return "强制置忙";
      else if (type == 23) return "强制登出";
      else return "正常呼叫";
    },
  },
  data() {
    return {
      apiUrl: `${this.baseUrls.callRingLog}/queryPage`,
      queryParams: {},
    };
  },
  created() {
    this.resetPara();
  },
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    getMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("month").format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("month").format("YYYY-MM-DD");
      this.doSearch();
    },
    getNowDay() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        username: "",
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      };
    },
  },
};
</script>

<style lang="scss">
.callRingLogContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }
}
</style>
