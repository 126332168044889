<template>
  <div class="callHistoryContainerFlexColumn">
    <div class="callHistoryContainerFlexColumn__top">
      <div class="title">历史数据统计</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-search-panel :params="queryParams" style="border: none" @search="doSearch">
        <dc-form-item label="时间类型" style="width: 50%">
          <el-radio-group v-model="queryParams.type">
            <el-radio v-for="item in historyType" :key="item.value" :label="item.value">{{ item.name }}</el-radio>
          </el-radio-group>
        </dc-form-item>
        <dc-form-item label="统计类型" style="width: 50%">
          <el-radio-group v-model="queryParams.typeCount">
            <el-radio v-for="item in historyTypeCount" :key="item.value" :label="item.value">{{ item.name }}</el-radio>
          </el-radio-group>
        </dc-form-item>
        <dc-form-item label="开始时间">
          <el-date-picker v-if="queryParams.type == 1" v-model="queryParams.startDate" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH" data-format="yyyy-MM-dd HH" value-format="yyyy-MM-dd HH" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="queryParams.type == 2" v-model="queryParams.startDate" format="yyyy-MM-dd" data-format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="queryParams.type == 3" v-model="queryParams.startDate" format="yyyy-MM" data-format="yyyy-MM" value-format="yyyy-MM" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="queryParams.type == 4" v-model="queryParams.startDate" format="yyyy" data-format="yyyy" value-format="yyyy" popper-class="customer-date-picker"></el-date-picker>
        </dc-form-item>
        <dc-form-item label="结束时间">
          <el-date-picker v-if="queryParams.type == 1" v-model="queryParams.endDate" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH" data-format="yyyy-MM-dd HH" value-format="yyyy-MM-dd HH" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="queryParams.type == 2" v-model="queryParams.endDate" format="yyyy-MM-dd" data-format="yyyy-MM-dd" value-format="yyyy-MM-dd" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="queryParams.type == 3" v-model="queryParams.endDate" format="yyyy-MM" data-format="yyyy-MM" value-format="yyyy-MM" popper-class="customer-date-picker"></el-date-picker>
          <el-date-picker v-if="queryParams.type == 4" v-model="queryParams.endDate" format="yyyy" data-format="yyyy" value-format="yyyy" popper-class="customer-date-picker"></el-date-picker>
        </dc-form-item>
      </dc-search-panel>
    </dc-search-panel>
    <div class="buttons">
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </div>
    <div ref="callHistoryChart" class="callHistoryContainerFlexColumn__body">
      <div id="callHistoryChart"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CallHistory",
  data() {
    return {
      callHistoryChart: null,
      queryParams: {
        type: 1,
        typeCount: 1,
      },
      historyType: [
        { name: "小时", value: 1 },
        { name: "天", value: 2 },
        { name: "月", value: 3 },
        { name: "年", value: 4 },
      ],
      historyTypeCount: [
        { name: "话务量", value: 1 },
        { name: "呼入详情", value: 2 },
        { name: "呼出详情", value: 3 },
      ],
      historyData: [],
    };
  },
  created() {
    this.resetPara();
  },
  mounted() {
    this.getHistoryData();
  },
  methods: {
    getHistoryData() {
      this.historyDialog = false;
      let url = `${this.baseUrls.callDataCount}/historyCount`;
      let params = this.queryParams;
      this.topsAjax.get(url, params).then((res) => {
        this.historyData = res;
        this.resizeHistory(res);
      });
    },
    resizeHistory(data) {
      document.getElementById("callHistoryChart").style.height = "0px";
      document.getElementById("callHistoryChart").style.height = this.$refs.callHistoryChart.clientHeight - 50 + "px";
      document.getElementById("callHistoryChart").style.width = this.$refs.callHistoryChart.clientWidth + "px";

      if (this.callHistoryChart) {
        this.callHistoryChart.dispose();
      }
      this.drawLineHistory(data);
    },
    drawLineHistory(data) {
      let day = [];
      let ntotal = [];
      let ncallInTotal = [];
      let ncallOutTotal = [];
      let nabandon = [];
      let ncallInAnswer = [];
      let ncallInAnswerPercent = [];
      let ncallInLost = [];
      let ncallInLostPercent = [];
      let ncallInAnswer15 = [];
      let ncallInAnswerPercent15 = [];
      let ncallOutAnswer = [];
      let ncallOutAnswerPercent = [];
      let ncallOutLost = [];
      let ncallOutLostPercent = [];
      let date = "";
      data.forEach((p) => {
        date = p.ndate;
        if (this.queryParams.type === 1) {
          day.push(`${p.ntime}时`);
        } else if (this.queryParams.type === 2) {
          day.push(p.ndate.substring(4, 8));
        } else {
          day.push(`${p.ndate}`);
        }

        ntotal.push(p.ntotal);
        ncallInTotal.push(p.ncallInTotal);
        ncallOutTotal.push(p.ncallOutTotal);
        nabandon.push(p.nabandon);
        ncallInAnswer.push(p.ncallInAnswer);
        ncallInAnswerPercent.push(p.ncallInAnswerPercent);
        ncallInLost.push(p.ncallInLost);
        ncallInLostPercent.push(p.ncallInLostPercent);
        ncallInAnswer15.push(p.ncallInAnswer15);
        ncallInAnswerPercent15.push(p.ncallInAnswerPercent15);
        ncallOutAnswer.push(p.ncallOutAnswer);
        ncallOutAnswerPercent.push(p.ncallOutAnswerPercent);
        ncallOutLost.push(p.ncallOutLost);
        ncallOutLostPercent.push(p.ncallOutLostPercent);
      });
      let option = [];
      if (this.queryParams.typeCount === 1) {
        date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
        option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            textStyle: {
              color: "#000",
            },
            data: ["总话务量", "呼入话务量", "呼出话务量", "排队用户放弃数"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#000",
              },
            },
            type: "category",
            data: day,
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#000",
              },
            },
            type: "value",
          },
          series: [
            {
              name: "总话务量",
              type: "line",
              data: ntotal,
              color: "blue",
            },
            {
              name: "呼入话务量",
              type: "line",
              data: ncallInTotal,
              color: "green",
            },
            {
              name: "呼出话务量",
              type: "line",
              data: ncallOutTotal,
              color: "red",
            },
            {
              name: "排队用户放弃数",
              type: "line",
              data: nabandon,
              color: "orange",
            },
          ],
        };
      } else if (this.queryParams.typeCount === 2) {
        date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
        option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            textStyle: {
              color: "#000",
            },
            data: ["呼入接听数", "呼入未接听数", "15s接听数"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#000",
              },
            },
            type: "category",
            data: day,
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#000",
              },
            },
            type: "value",
          },
          series: [
            {
              name: "呼入接听数",
              type: "line",
              data: ncallInAnswer,
              color: "green",
            },
            {
              name: "呼入未接听数",
              type: "line",
              data: ncallInLost,
              color: "red",
            },
            {
              name: "15s接听数",
              type: "line",
              data: ncallInAnswer15,
              color: "orange",
            },
          ],
        };
      } else if (this.queryParams.typeCount === 3) {
        date = moment(date, "YYYYMMDD").format("YYYY年MM月DD日");
        option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            textStyle: {
              color: "#000",
            },
            data: ["呼出接听数", "呼出未接听数"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                color: "#000",
              },
            },
            type: "category",
            data: day,
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#000",
              },
            },
            type: "value",
          },
          series: [
            {
              name: "呼出接听数",
              type: "line",
              data: ncallOutAnswer,
              color: "green",
            },
            {
              name: "呼出未接听数",
              type: "line",
              data: ncallOutLost,
              color: "red",
            },
          ],
        };
      }
      //基于准本好的DOM，初始化echarts实例
      this.callHistoryChart = this.$echarts.init(document.getElementById("callHistoryChart"));
      //绘制图表
      this.callHistoryChart.setOption(option);
    },
    doSearch() {
      this.getHistoryData();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
      this.getHistoryData();
    },
    resetPara() {
      this.queryParams = {
        type: 1,
        typeCount: 1,
        startDate: moment().startOf("day").format("YYYY-MM-DD HH"),
        endDate: moment().endOf("day").format("YYYY-MM-DD HH"),
      };
    },
  },
};
</script>

<style scoped lang="scss">
.callHistoryContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }

    .tips {
      margin-top: 10px;
      padding: 10px;
      background-color: #f6f6f6;
    }

    .label {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }
  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }
}
</style>
